<template>
  <div>
    <!-- catches all the outer class definitions -->
    <div class="header-entry" :class="{ 'align-right': alignRight }">
      <div class="first-column" :class="{ 'sortable': sortable }" @click="onSort">
        <span>{{ title }}</span>
        <span v-if="unit" class="unit">{{ unit }}</span>
        <slot v-if="$slots.unit" name="unit" />
      </div>
      <InfoBox v-if="infoboxTitle" :title="infoboxTitle">{{ infoboxText }}</InfoBox>
      <slot name="infobox"> </slot>
      <div v-if="sortable && sorted" class="sort-icon" @click="onSort">
        <img v-show="!sortReverse" class="icon" src="/icons/sort-ascending.svg" />
        <img v-show="sortReverse" class="icon" src="/icons/sort-descending.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import InfoBox from '@/components/shared/InfoBox.vue'

export default {
  name: 'HeaderEntry',
  components: { InfoBox },

  props: {
    title: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: false,
    },
    infoboxTitle: {
      type: String,
      required: false,
    },
    infoboxText: {
      type: String,
      required: false,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    sorted: {
      type: Boolean,
      default: false,
    },
    sortReverse: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onSort() {
      if (this.sortable) {
        this.$emit('update:sort')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-entry {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.align-right {
  text-align: right;
  justify-content: flex-end;
}

.first-column {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
}

.unit {
  color: #888;
  font-weight: 400;
  margin-left: 5px;
}
.sortable {
  cursor: pointer;
}

.sort-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  cursor: pointer;
}
</style>
