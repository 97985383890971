// https://github.com/dkfbasel/vuex-i18n/issues/16
import compassApi from '@/services/compass-api'
import { saveFile } from '@/services/util'
import { Chart } from 'chart.js'
import _ from 'lodash'

const arrayToCsv = (arr) => {
  let line = []
  for (let i = 0; i < arr.length; ++i) {
    let item = arr[i]
    if (item && item.indexOf && (item.indexOf(',') !== -1 || item.indexOf('"') !== -1)) {
      item = '"' + item.replace(/"/g, '""') + '"'
    }
    line.push(item)
  }
  return line.join(';')
}

const saveCsv = (data, filename) => {
  saveFile(data, filename, 'text/csv')
}

const exportBuildingJson = async (building, withGis) => {
  const exportData = await compassApi.call(`/buildings/${building.id}/export?gis=${withGis}`)
  saveJson(exportData, `${building.identifier}.json`)
}

const exportPortfolioBuildingsJson = async (portfolio, query, withGis) => {
  const queryString = JSON.stringify(query)
  const exportData = await compassApi.call(
    `/portfolios/${portfolio.id}/buildings/export?gis=${withGis}&query=${queryString}`
  )
  saveJson(exportData, `${portfolio.name}_buildings.json`)
}

const exportPortfolioJson = async (portfolio, withGis) => {
  const exportData = await compassApi.call(`/portfolios/${portfolio.id}/export?gis=${withGis}`)
  saveJson(exportData, `${portfolio.name}.json`)
}

const saveJson = (data, filename) => {
  const stringData = JSON.stringify(data, null, 2)
  let a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  const blob = new Blob([stringData], { type: 'text/plain' })
  let url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
}

const chartToCsv = (chartData, chartOptions) => {
  const xAxisLabel =
    chartOptions.scales && chartOptions.scales.xAxis && chartOptions.scales.xAxis.title
      ? chartOptions.scales.xAxis.title.text
      : null
  const yAxisLabel =
    chartOptions.scales && chartOptions.scales.yAxis && chartOptions.scales.yAxis.title
      ? chartOptions.scales.yAxis.title.text
      : null
  let header = [xAxisLabel]
  for (let datasetIdx = 0; datasetIdx < chartData.datasets.length; datasetIdx++) {
    const columnLabel = chartData.datasets[datasetIdx].label
    header.push(`${columnLabel} - ${yAxisLabel}`)
  }
  let rows = []
  for (let rowIdx = 0; rowIdx < chartData.labels.length; rowIdx++) {
    const rowLabel = chartData.labels[rowIdx]
    let rowValues = [rowLabel]
    for (let datasetIdx = 0; datasetIdx < chartData.datasets.length; datasetIdx++) {
      const rowValue = chartData.datasets[datasetIdx].data[rowIdx]
      rowValues.push(rowValue)
    }
    rows.push(arrayToCsv(rowValues))
  }
  return [arrayToCsv(header), ...rows]
}

const pieChartToCsv = (chartData, groupingName) => {
  let header = [groupingName]
  for (let datasetIdx = 0; datasetIdx < chartData.datasets.length; datasetIdx++) {
    const columnLabel = chartData.datasets[datasetIdx].label
    header.push(columnLabel)
  }
  let rows = []
  for (let rowIdx = 0; rowIdx < chartData.labels.length; rowIdx++) {
    const rowLabel = chartData.labels[rowIdx]
    let rowValues = [rowLabel]
    for (let datasetIdx = 0; datasetIdx < chartData.datasets.length; datasetIdx++) {
      const rowValue = chartData.datasets[datasetIdx].data[rowIdx]
      rowValues.push(rowValue)
    }
    rows.push(arrayToCsv(rowValues))
  }
  return [arrayToCsv(header), ...rows]
}

const chartToExportCanvas = (sourceChart, exportCanvas, chartData, options, initSize) => {
  const exportOptions = _.cloneDeep(options)
  const exportChartData = _.cloneDeep(chartData)
  const exportChartConfig = {
    type: sourceChart.config.type,
    data: exportChartData,
    options: {
      ...exportOptions,
      layout: {
        ...(exportOptions?.layout || {}),
        padding: 20,
      },
      animation: false,
      plugins: {
        ...(exportOptions?.plugins || {}),
        canvasColor: {
          color: 'white',
        },
      },
    },
  }
  const exportSize = _.clone(initSize)

  if (!_.isEmpty(options?.plugins?.legend)) {
    // Filter out the hidden items from the export chart data
    sourceChart.legend.legendItems.forEach((item) => {
      if (item.hidden) {
        exportChartData.datasets.forEach((dataset, index) => {
          if (item.datasetIndex === index) {
            exportChartData.datasets[index].data = []
          } else if (item.index !== undefined) {
            dataset.data[item.index] = null
          } else if (item.text === dataset.label) {
            // Edge case when the filtering is applied to the legend items
            // and datasets are filtered on legend item click (e.g. EfficiencyClassChart)
            exportChartData.datasets[index].data = []
          }
        })
      }
    })
    // Filter out the hidden items from the export chart legend
    exportChartConfig.options.plugins.legend = {
      ...(options?.plugins?.legend || {}),
      labels: {
        ...(options?.plugins?.legend?.labels || []),
        filter: (legendItem) => {
          const sourceFn = options?.plugins?.legend?.labels?.filter
          // Apply the source filter function if it is provided
          const sourceFnPassed = sourceFn ? sourceFn(legendItem) : true
          const showItem = !_.find(sourceChart.legend.legendItems, { text: legendItem.text }).hidden
          // Show only the legend items that are not hidden in the source chart
          return sourceFnPassed && showItem
        },
      },
    }
  }

  // Create a new Chart instance with the export canvas
  const exportChart = new Chart(exportCanvas, exportChartConfig)

  // Adjust the export canvas size to include the legend
  exportSize.height = exportSize.height + (exportChart.legend?.height || 0)
  exportChart.resize(exportSize.width, exportSize.height)

  return { chart: exportChart }
}

const savePng = (chart, filename) => {
  const link = document.createElement('a')
  link.href = chart.toBase64Image()
  link.download = filename
  link.click()
  link.remove()
}

const exportService = {
  arrayToCsv,
  chartToCsv,
  pieChartToCsv,
  saveCsv,
  saveJson,
  exportBuildingJson,
  exportPortfolioJson,
  exportPortfolioBuildingsJson,
  chartToExportCanvas,
  savePng,
}

export default exportService
